<template>
  <div class="container">
    <PageTitle title="Sign Out"/>
    <div class="container is-mobile box logout-error-message has-background-white-ter has-text-centered">
      <Spinner v-show="signOutForm.inProgress" message="JDoodle is trying to sign you out, Please wait..."/>
      <DoodleMessage message="Unable to Logout. Please try again or contact JDoodle Support."
                     v-show="!signOutForm.inProgress && signOutForm.isFailed"/>
      <div  v-show="!signOutForm.inProgress && !signOutForm.isFailed">
        <font-awesome-icon icon="exclamation-circle" class="is-size-3 has-text-danger"/> <br/>
        <span class="has-text-weight-bold is-size-6-desktop is-size-7-mobile error-message">
            You are Logged out. <br/>Thanks for using JDoodle.
          </span>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '../components/Utils/Spinner'
import PageTitle from '../components/PageTitle'
import DoodleMessage from '../components/Utils/DoodleMessage'

export default {
  name: 'logout',
  components: { DoodleMessage, PageTitle, Spinner },
  data: function () {
    return {
      signOutForm: {
        inProgress: false,
        isFailed: false
      }
    }
  },
  mounted () {
    if (this.$store.state.isUserLoggedIn === false) {
      this.$router.push('/')
    }

    this.signOutForm.inProgress = true
    let url = '/api/logout/logoff'

    $.ajax({
      type: 'GET',
      url: url
    }).done((data, status) => {
      if (data.success) {
        document.location.href = '/'
      }
    }).fail((jqXHR, textStatus, errorThrown) => {
      this.signOutForm.isFailed = true
      window.jda.exception(url + ': ' + errorThrown)
    }).always(() => {
      this.signOutForm.inProgress = false
    })
    window.jda.pageEvent(window.jda.CATEGORY.GLOBAL, 'logout', '')
  }
}
</script>

<style scoped lang="scss">
  .logout-error-message {
    margin-top: 3em;
    padding: 3em;
  }
  .error-message {
    margin-left: 2em;
  }
</style>
